<template>
  <div>
    <div class="addPages">
      <el-breadcrumb separator="/" style="margin:10px 0 0 0;">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>客户档案</el-breadcrumb-item>
        <el-breadcrumb-item>司机信息</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 表单区域 -->
      <el-form :model="driverForm" :rules="rules" ref="driverForm" label-width="160px">
        <!-- 角色属性 -->
        <div class="title">角色属性</div>
        <el-form-item label="角色属性" prop="IdentityType">
          <el-select v-model="driverForm.IdentityType" placeholder="请选择" disabled>
            <el-option v-for="item in rolelist" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 认证资料 -->
        <div class="title">认证资料</div>
        <div class="content">
          <div class="upBox">
            <el-image class="img" :src="driverImg.idFace.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">驾驶员身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image class="img" :src="driverImg.idBack.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">驾驶员身份证(反面)</div>
          </div>
          <div class="upBox" v-if="driverImg.driverFace.picUrl">
            <el-image class="img" :src="driverImg.driverFace.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">机动车驾驶证(正页)</div>
          </div>
          <div class="upBox" v-if="driverImg.driverBack.picUrl">
            <el-image class="img" :src="driverImg.driverBack.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">机动车驾驶证(副页)</div>
          </div>
        </div>
        <div class="content">
          <div class="upBox" v-if="driverImg.legalPersonBankCard.picUrl">
            <el-image class="img" :src="driverImg.legalPersonBankCard.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">银行卡</div>
          </div>
          <div class="upBox" v-if="driverImg.converLicence.picUrl">
            <el-image class="img" :src="driverImg.converLicence.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">道路运输从业资格证</div>
          </div>
          <div class="upBox" v-if="driverImg.driverPhotoURL.picUrl">
            <el-image class="img" :src="driverImg.driverPhotoURL.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">人员照片</div>
          </div>
          <div class="upBox" v-if="driverImg.driverIDCardPhoto.picUrl">
            <el-image class="img" :src="driverImg.driverIDCardPhoto.picUrl" :preview-src-list="srcList"></el-image>
            <div class="upText">手持身份证照</div>
          </div>
        </div>
        <!-- 基本资料 -->
        <div class="title">基本资料</div>
        <div class="content content2" style="justify-content: left">
          <el-form-item label="人员姓名" prop="DriverName" required>
            <el-input v-model="driverForm.DriverName" placeholder="请输入人员姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="DriverIDCard" required>
            <el-input v-model="driverForm.DriverIDCard" :disabled="UserID ? true : false" placeholder="请输入身份证号">
            </el-input>
          </el-form-item>
          <el-form-item label="身份证有效期" prop="DriverIDCardEndDate">
            <el-date-picker v-model="driverForm.DriverIDCardEndDate" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择身份证有效期"></el-date-picker>
          </el-form-item>
          <el-form-item label="身份证起始日期" prop="DriverIDCardStartDate">
            <el-date-picker v-model="driverForm.DriverIDCardStartDate" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择身份证起始日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="人员手机号" prop="TelPhone" required>
            <el-input v-model="driverForm.TelPhone" placeholder="请输入人员手机号" disabled></el-input>
          </el-form-item>
          <el-form-item label="准驾类型" prop="DriverLicenseType" required>
            <el-select v-model="driverForm.DriverLicenseType" clearable placeholder="请选择准驾类型">
              <el-option v-for="item in arr2" :key="item.Name" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="驾驶证起始日期" prop="DriverLicenseDateStart" required>
            <el-date-picker v-model="driverForm.DriverLicenseDateStart" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择驾驶证起始日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="驾驶证有效期" prop="DriverLicenseDateEnd" required>
            <el-date-picker v-model="driverForm.DriverLicenseDateEnd" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择驾驶证有效期"></el-date-picker>
          </el-form-item>
          <el-form-item label="性别" prop="DriverSex">
            <el-select v-model="driverForm.DriverSex" clearable placeholder="请选择性别">
              <el-option v-for="item in sexList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="民族" prop="Ethnic">
            <el-input v-model="driverForm.Ethnic" placeholder="请输入民族"></el-input>
          </el-form-item>
          <el-form-item label="出生日期" prop="Birthday">
            <el-date-picker v-model="driverForm.Birthday" type="date" value-format="yyyy-MM-dd" placeholder="请选择出生日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地址" prop="Address">
            <el-input v-model="driverForm.Address" placeholder="请输入地址"></el-input>
          </el-form-item>
          <el-form-item label="银行卡号" prop="BankCardNo">
            <el-input v-model="driverForm.BankCardNo" placeholder="请输入银行卡号"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证档案编号" prop="DriverLicenseArchives">
            <el-input v-model="driverForm.DriverLicenseArchives" placeholder="请输入驾驶证档案编号"></el-input>
          </el-form-item>
          <el-form-item label="驾驶证发证机关" prop="DriverLicesenAuthority" required>
            <el-input v-model="driverForm.DriverLicesenAuthority" placeholder="请输入驾驶证发证机关"></el-input>
          </el-form-item>
          <el-form-item label="归属类型" prop="DriverAttribution">
            <el-select v-model="driverForm.DriverAttribution" clearable placeholder="请选择归属类型">
              <el-option v-for="item in affiliation" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="从业资格证号" prop="RTQCertificate">
            <el-input v-model="driverForm.RTQCertificate" placeholder="请输入从业资格证号"></el-input>
          </el-form-item>
          <el-form-item label="从业资格证有效期" prop="RTQCertificateDate">
            <el-date-picker v-model="driverForm.RTQCertificateDate" type="date" value-format="yyyy-MM-dd"
              placeholder="请选择从业资格证有效期"></el-date-picker>
          </el-form-item>
          <el-form-item label="运营机构" prop="Mechanism">
            <el-input v-model="driverForm.Mechanism" placeholder="" readonly></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="head">
        <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()">返回</el-button>
        <el-button size="medium" type="primary" icon="el-icon-edit" @click="saveEdit()">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TCDriverInfo, PutDriver } from "@/api/transport/driverManage/index";
import { getDataDict } from '@/api/common/common'
export default {
  data() {
    return {
      // 性别
      sexList: [],
      affiliation: [],
      rules: {
        DriverLicenseType: [
          { required: true, message: '请选择准驾类型', trigger: 'change' }
        ],
        DriverLicenseDateStart: [
          { required: true, message: '请选择驾驶证起始日期', trigger: 'change' }
        ],
        DriverLicenseDateEnd: [
          { required: true, message: '请选择驾驶证有效期', trigger: 'change' }
        ],
        DriverLicesenAuthority: [
          { type: 'string', required: true, message: '请输入驾驶证发证机关', trigger: 'blur' }
        ]
      },
      // 图片放大预览
      srcList: [],
      // 通过Icon
      succIcon: 'el-icon-check',
      // 准驾车型
      arr2: [],
      // 基本资料
      driverForm: {
        DriverName: '', //驾驶员姓名
        TelPhone: '', //登录账号
        Mechanism: '运服通平台', //运营机构
        DriverAttribution: '', //人员归属
        DriverLicenseType: '', //准驾车型
        DriverLicenseArchives: '', //驾驶证档案编号
        DriverLicesenAuthority: '', //驾驶证发证机关
        DriverLicenseDateEnd: '', //驾驶证有效期限至
        RTQCertificateDate: '', //从业资格证有效期限限
        DriverSex: '', //性别
        DriverIDCard: '', //身份证号
        RTQCertificate: '', //道路运输从业资格证
        DriverLicense: '', //机动车驾驶证号
        DriverIDCardEndDate: '', //身份证有效期至
        address: '', // 身份证地址
        IdentityType: '', //角色属性
        BankCardNo: '', //银行卡
        DriverIDCardStartDate: '',//身份证起始日期
        VehicleCarNumber: '', //车牌号
        DriverLicenseDateStart: ''
      },
      // 人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        converLicence: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        driverPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        driverIDCardPhoto: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        }
      },
      pics: {},
      // 角色属性
      rolelist: [],
      // tab状态
      urlType: '',
      pageIndex: 1
    }
  },
  created() {
    this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    this.UserID = this.$route.query.DriverIDCard
    this.urlType = this.$route.query.urlType
    // 获取人员详情信息
    this.getDriverDeatils(this.$route.query.DriverIDCard)
    // 获取角色属性
    getDataDict({ type: 50 }).then(res => {
      this.rolelist = res.patterSetInfo
    })
    // 性别
    getDataDict({ type: 9 }).then(res => {
      this.sexList = res.patterSetInfo
    })
    // 准驾类型
    getDataDict({ type: 7 }).then(res => {
      this.arr2 = res.patterSetInfo
    })
    // 归属
    getDataDict({ type: 16 }).then(res => {
      this.affiliation = res.patterSetInfo
    })
  },
  methods: {
    // 保存编辑
    saveEdit() {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        driver_baseInfo: {
          ...this.driverForm
        },
        driver_confirmInfo: {
          ...this.pics
        }
      }
      PutDriver(params).then(() => {
        this.$message.success("保存成功")
        this.getDriverDeatils(this.UserID)
        loading.close()
      }).catch(()=>{
        loading.close()
      })
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.urlType,
          pageIndex: this.pageIndex
        },
      });
    },
    // 获取人员详情信息
    getDriverDeatils(DriverIDCard) {
      TCDriverInfo({ DriverIDCard }).then(res => {
        this.driverForm = res.baseInfo
        this.pics = res.confirm
        this.driverImg.driverBack.picUrl = res.confirm.DriverLicenseBackURL
        this.driverImg.idBack.picUrl = res.confirm.DriverCardBackURL
        this.driverImg.driverFace.picUrl = res.confirm.DriverLicenseOnURL
        this.driverImg.converLicence.picUrl = res.confirm.RTQCertificateURL
        this.driverImg.driverPhotoURL.picUrl = res.confirm.DriverPhotoURL
        this.driverImg.driverIDCardPhoto.picUrl = res.confirm.DriverIDCardPhoto
        this.driverImg.idFace.picUrl = res.confirm.DriverCardOnURL
        this.driverImg.legalPersonBankCard.picUrl = res.confirm.BankCardURL
        // 用于大图展示
        if (res.confirm.BankCardURL) {
          this.srcList.push(res.confirm.BankCardURL);
        }
        if (res.confirm.DriverCardOnURL) {
          this.srcList.push(res.confirm.DriverCardOnURL);
        }
        if (res.confirm.DriverCardBackURL) {
          this.srcList.push(res.confirm.DriverCardBackURL);
        }
        if (res.confirm.DriverLicenseOnURL) {
          this.srcList.push(res.confirm.DriverLicenseOnURL);
        }
        if (res.confirm.DriverLicenseBackURL) {
          this.srcList.push(res.confirm.DriverLicenseBackURL);
        }
        if (res.confirm.RTQCertificateURL) {
          this.srcList.push(res.confirm.RTQCertificateURL);
        }
        if (res.confirm.DriverPhotoURL) {
          this.srcList.push(res.confirm.DriverPhotoURL);
        }
        if (res.confirm.DriverIDCardPhoto) {
          this.srcList.push(res.confirm.DriverIDCardPhoto);
        }
      })
    }
  },
}
</script>

<style lang="scss">
.addPages .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>

<style scoped lang="scss">
@import '../../../../assets/style/variable.scss';

.addPages::-webkit-scrollbar {
  width: 4px;
}

.addPages::-webkit-scrollbar-thumb {
  background: #ddd;
}

.addPages {
  padding: 0px 10px;
  box-sizing: border-box;
  height: 85vh;
  background: #fff;
  border-radius: 12px;
  overflow: auto;

  .btnWrap {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .img {
    width: 278px;
    height: 174px;
    border-radius: 10px;
  }

  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 0 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }
}
</style>
<style>
/* input宽度 */
.addPages .el-form .content2 .el-form-item {
  width: 400px !important;
}

.addPages .el-form .content2 .el-date-editor,
.addPages .el-form .content2 .el-select {
  width: 240px !important;
}
</style>